import React from 'react'
import { LayoutNoSideNav } from '../components/organisms/Layout/LayoutNoSideNav'
import { SEO } from '../components/data/SEO'
import { PrivacyPolicy } from '../components/organisms/PrivacyPolicy/PrivacyPolicyAfter202406'
import { ENGLISH_TITLE } from '../components/data/English'

const Page = () => {
  return (
    <LayoutNoSideNav
      heading="プライバシーポリシー（2024年6月改訂後）"
      headingEnglish={ENGLISH_TITLE.PrivacyPolicy}
      BreadcrumbData={[
        { title: 'プライバシーポリシー（2024年6月改訂後）', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pb-12 bg-white">
        <PrivacyPolicy />
      </div>
    </LayoutNoSideNav>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEO title="プライバシーポリシー（2024年6月改訂後）" url="/privacy" />
)
